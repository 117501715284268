<template>
  <div class="main">
       <TheTopbar/>
    <slot />

    <div> 
        <div class="options-bar">
                    <div class="options">
                        <button class="btn btn-main" :class="{ active: state == 'main'}" @click="changeState('main')" >OVERVIEW</button>
                        <button class="btn btn-main" :class="{ active: state == 'compliance'}" @click="changeState('compliance')" >COMPLIANCE</button>
                    </div>
                </div>
    </div>
    <div v-if="state == 'main'"> 
        <div class="" >
                
                <div class="lowerGraphBox">
                    <div class="lowerBox">
                      
                        <div v-if="isLoading" class="loading-spinner"></div>
                        <label style="width: 300px;">Total Number of Integrated To the Central Audit System</label>
                        <p>{{ details.integratedCentralAudit }}</p>
                    </div>
                    <div class="lowerBox">
                        <button @click="openPending" class="btn btn-info" style="float: right !important;" :disabled="details.pendingApplicants == 0" v-b-tooltip.hover title="Click to show all incomplete applications">view</button>
                        <div v-if="isLoading" class="loading-spinner"></div>
                        <label style="width: 300px;">Total Number of Pending Applications</label>
                        <p>{{ details.pendingApplicants }}</p>
    
                    </div>
                    
                    <div class="lowerBox" >
                        <button @click="openProvisionalExpiry" class="btn btn-info" style="float: right !important;" v-b-tooltip.hover title="Click to show all provisional licensees">show</button>
                        <div v-if="isLoading" class="loading-spinner"></div>
                        <label style="width: 300px;">Total Number of Provisional Licensees</label>
                        <p>{{ details.provisionalLicense }}</p>
                    </div>
                    <div class="lowerBox"  >
                        <button @click="openFullExpiry" class="btn btn-info" style="float: right !important;" v-b-tooltip.hover title="Click to show all full licensees">show</button>
                        <div v-if="isLoading" class="loading-spinner"></div>
                        <label style="width: 300px;">Total Number Fully licensed Operators</label>
                        <p>{{ details.fullLicense }}</p>
    
                    </div>
                    
                </div>
            </div>
            <div> 
                <div v-if="showPending"> 
                    <div class="market-content">
                        <label>Pending Applicants</label>
                        <table class="table"> 
                            <thead>
                                <th>Host</th>
                                <th>Entity Id</th>
                                <th>Verification Status</th>
                          
                            </thead>
                            <tbody v-for="data in pendingApplicants" :key="data.id">
                                <td>{{ data.signupHost }}</td>
                                <td><button class="btn btn-info"> <a :href="'/#/customer-profile/' + data.entityId" style="color: white !important;">{{ data.entityId }}</a></button></td>
                                <td>{{ data.verificationStatus }}</td>
                              
                            </tbody>
                            
                        </table>
                    </div>
                </div>
                <div v-if="showProvExpiry"> 
                    <div class="market-content">
                        <label>Provisional Licensees</label>
                        <table class="table"> 
                            <thead>
                                <th>Host</th>
                                <th>Entity Id</th>
                                <th>Expiration Date</th>
                          
                            </thead>
                            <tbody v-for="data in sortedProvisional" :key="data.id" v-if="data.provisionalLicenseExpirationDate != null">
                               
                                <td>{{ data.signupHost }}</td>
                                 <td><button class="btn btn-info"> <a :href="'/#/customer-profile/' + data.entityId" style="color: white !important;">{{ data.entityId }}</a></button></td>
                                <td>{{ formatDate(data.provisionalLicenseExpirationDate) }}</td>
                         
                            </tbody>
                            
                        </table>
                    </div>
                </div>
                <div v-if="showFullExpiry"> 
                    <div class="market-content">
                        <label>Full Licensees</label>
                        <table class="table"> 
                            <thead>
                                <th>Host</th>
                                <th>Entity Id</th>
                                <th>Expiration Date</th>
                            
                            </thead>
                            <tbody v-for="data in  sortedFull" :key="data.id" v-if="data.fullLicenseExpirationDate != null">
                                <td>{{ data.signupHost }}</td>
                                <td><button class="btn btn-info">  <a :href="'/#/customer-profile/' + data.entityId" style="color: white !important;"> {{ data.entityId }}</a></button></td>
                                <td>{{ formatDate(data.fullLicenseExpirationDate) }}</td>
                       
                            </tbody>
                     
                        </table>
                    </div>
                </div>
            </div>
            <div class="">
            <div class="upperGraphBox">
                <div class="upperBox">
                    <div v-if="isLoading" class="loading-spinner"></div>
                    <!-- <MapChart :map-data="mapData"/> -->
                         <MapChart2/>
                </div>
            </div>
            </div>
        
            <div class="">
                <div class="graphBox">
                    <div class="box d-flex justify-content-between">
                        <div v-if="isLoading" class="loading-spinner"></div>
                        <div> 
                            <p>Gaming Operators</p>
                            <PieChart2 v-if="customersGaming.length > 0"/>
                            <h6 v-if="customersGaming.length == 0">NO DATA FOUND</h6>
                        </div>
                        <div class="small-box d-flex justify-content-between" v-if="labelG.length > 0">
                            <div> <p v-for="status in labelG" :key="status.id">{{ status }} </p> </div>
                            <div class="ml-2"> <p  v-for="data in dataValueG" :key="data.id">{{ data }} </p> </div>
                        
                        </div>
                    
                    </div>          
                    <div class="box d-flex justify-content-between">
                        <div v-if="isLoading" class="loading-spinner"></div>
                        <div> 
                            <p>Fintech Operators</p>
                            <PieChart v-if="!isLoading && customersFintech.length > 0"/>
                            <h6 v-if="customersFintech.length == 0">NO DATA FOUND</h6>
                        </div>
                        <div class="small-box d-flex justify-content-between">
                            <div> <p v-for="status in labelF" :key="status.id">{{ status }} </p> </div>
                            <div class="ml-2"> <p  v-for="data in dataValueF" :key="data.id">{{ data }} </p> </div>
                        
                        </div>
                    </div>  
                </div>   
            </div>

    </div>
   
  </div>
    
        
    
   
</template>
<script>
import TheTopbar from '../components/TheTopbar.vue'
import moment from 'moment'
import PieChart from './Chart/PieChart.vue'
import PieChart2 from './Chart/PieChart2.vue'
// import MapChart from './Chart/MapChart.vue'
import MapChart2 from './Chart/MapChart2.vue'
import VueWorldMap from "vue-world-map";




export default {
    name: 'Dashboard',
    components: {
        TheTopbar,
        PieChart,
        PieChart2,
        // MapChart,
        VueWorldMap,
        MapChart2

          
    },
    data:() => {
        return{
            state:'main',
            pending: [],
            customersFintech:[],
            customersGaming:[],
            fintech:[],
            gaming:[],
            numberOfDays: '31',
            signupHost:'',
            noOfFintech:'',
            labelG: [],
            dataValueG: [],
            labelF: [],
            dataValueF: [],
            isLoading: false,
            details:'',
            showProvExpiry: false,
            showFullExpiry: false,
            sortedData: [],
            fullExpiryDate:[],
            daysLeft:'',
            showPending: false,
           
          }
    },
      

    computed:{
        
        init() {
          return this.$store.state.init;
        },
        show() {
          return this.$store.state.show;
        },
        totalOperators(){
            return this.customersFintech.length + this.customersGaming.length;
        },
        sortedProvisional(){
            const mergedProvisional = [...this.fintech, ...this.gaming];
            return mergedProvisional.sort((a,b) => new Date(a.provisionalLicenseExpirationDate) - new Date(b.provisionalLicenseExpirationDate))
            
        },
        sortedFull(){
            const mergedFull = [...this.fintech, ...this.gaming];
            
            return mergedFull.sort((a,b) => new Date(a.fullLicenseExpirationDate) - new Date(b.fullLicenseExpirationDate))
        },
        pendingApplicants(){
            const mergedProvisional = [...this.fintech, ...this.gaming];
            return mergedProvisional.filter(item => item.verificationStatus != 'VERIFIED')
        }
       
      
    },
  
    
    methods:{
        openPending(){
            this.getAllDetailsFintech();
            this.getAllDetailsGaming();

            this.showPending = true;
            this.showProvExpiry = false;
            this.showFullExpiry = false
       },
        formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY');
        },
        
        viewLogs(){

        },
        openProvisionalExpiry(){
            this.getAllDetailsFintech();
            this.getAllDetailsGaming();
            this.showPending = false;
            this.showProvExpiry = true;
            this.showFullExpiry = false
        },
        openFullExpiry(){
            this.getAllDetailsGaming();
            this.getAllDetailsFintech();
            this.showPending = false;
            this.showFullExpiry = true;
            this.showProvExpiry = false;
        },
        getAllDetailsFintech(){
                const params = {
		          signupHost: 'fintech.ceza.org',
                  limit: '50000',
                  offset: '0',
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customersearch/csv',
                    params
                    };

				this.axios(options)
				.then((response) => { 
                 this.fintech = response.data
                 
                 
                })
            },
        getAllDetailsGaming(){
                const params = {
		          signupHost: 'gaming.ceza.org',
                  limit: '50000',
                  offset: '0',
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customersearch/csv',
                    params
                    };

				this.axios(options)
				.then((response) => { 
                 this.gaming = response.data

                 let expiryArr = []
                 let provExpiryArr = []
                 this.gaming.forEach((el, i) => {
                    expiryArr.push(el.fullLicenseExpirationDate);
                    provExpiryArr.push(el.provisionalLicenseExpirationDate);
 
                 })
                })
            },
        formatDash: function(date) {
        return moment(new Date(date)).format('YYYY-MM-DD');
        },
        calculateDaysLeft(expirationDate){

            this.fullExpiryDate = expirationDate
            console.log(this.formatDash(this.fullExpiryDate))
    
                // Get the current date
                const currentDate = new Date();
                console.log(currentDate)

                // Calculate the difference in milliseconds between the expiration date and the current date
                const differenceMs = this.formatDash(this.fullExpiryDate) - currentDate;

                // Convert milliseconds to days
                const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

               
                this.daysLeft = daysLeft

        },
     

        changeState(state) {
            this.state = state;
          if(this.state == 'compliance'){
            window.location.href="/#/compliance-dashboard"
          }
           
          },
        getDetails(){
            this.isLoading = true
                const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/businessfile/details',
                    
                    };

              this.axios(options)
              .then((response) => { 
                this.isLoading = false
                console.log(response.data)
                this.details = response.data
                });
    },
        searchByGaming(){
            this.isLoading = true
             const params = {
		          signupHost: 'gaming.ceza.org',
                  itemsPerPage: '100',
                  limit: '50000',
                  offset: '0',
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customerSearchBySignupHost/',
                    params
                    };

              this.axios(options)
              .then((response) => { 
                this.isLoading = false
                this.customersGaming = response.data
                
                  let statusArr = [];

                  response.data.forEach((el, i) => {
                      statusArr.push(el.verificationStatus);
                  })
                  

                  let countMap = statusArr.reduce((el, i) => {
                      el[i] = (el[i] || 0) + 1;
                      return el;
                  }, {});

                  let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                  for(let i = 0;i < countArray.length ; i++) {

                      this.labelG.push(countArray[i].value);
                      this.dataValueG.push(countArray[i].count);
                   
                  }

     
                });
        },
        searchByFintech(){
            this.isLoading = true
             const params = {
		          signupHost: 'fintech.ceza.org',
                  itemsPerPage: '100',
                  limit: '50000',
                  offset: '0',
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customerSearchBySignupHost/',
                    params
                    };

              this.axios(options)
              .then((response) => { 
                this.isLoading = false
                this.customersFintech = response.data
                  let statusArr = [];

                  response.data.forEach((el, i) => {
                      statusArr.push(el.verificationStatus);
                  })
                  

                  let countMap = statusArr.reduce((el, i) => {
                      el[i] = (el[i] || 0) + 1;
                      return el;
                  }, {});

                  let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                  for(let i = 0;i < countArray.length ; i++) {

                      this.labelF.push(countArray[i].value);
                      this.dataValueF.push(countArray[i].count);
                   
                  }

     
                });
    },
      
        
            checkSession: function() {

				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/sessionCheck',
				};

				this.axios(options)
				.then((response) => { 
                    console.log(response.data)
                  
					}).catch((err) => {
						
					if(err.response.data.msgCode == '00001') {
						window.location.href = "/#/login";
					}  
					})
			},
          
    },
    mounted(){
        this.checkSession();
        // this.getPendingSignup();
        this.searchByFintech();
        this.searchByGaming();
        this.getDetails();
       this.getAllDetailsFintech();
       this.getAllDetailsGaming();
    }
   
     


        
}
</script>

<style lang="scss" scoped>
.upperGraphBox{
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px;
    display: grid;
    grid-template-columns: 4fr;
    grid-gap: 10px;
    min-height: 300px;
    min-width: 200px;

}
.upperGraphBox .upperBox{
    position: relative;
    background: white;
    padding: 20px;
    width: 100%;
    box-shadow: 0 7px 25px rgba(0,0,0,08);
    border-radius: 20px;
}
.lowerGraphBox{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    min-height:100px;
    min-width: 100px;

}
.lowerGraphBox .lowerBox{
    position: relative;
    background: white;
    padding: 10px;
    width: 100%;
    box-shadow: 0 7px 25px rgba(0,0,0,08);
    border-radius: 20px;
}
.graphBox{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 30px;
    min-height: 200px;

}
.graphBox .box{
    position: relative;
    background: white;
    padding: 20px;
    width: 100%;
    box-shadow: 0 7px 25px rgba(0,0,0,08);
    border-radius: 20px;
}
.small-box{
    position: relative;
    background: white;
    padding: 50px;
    width: 40%;
    height: 180px;
    box-shadow: 0 7px 25px rgba(0,0,0,08);
    border-radius: 20px;
    margin: auto 20px !important;
}

.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

th, td {
  padding: 0.5em;
  z-index: 9999 !important;
  color: black;
}
tr{
  width: 100%;
  padding: 0.5em 2em;
}
.table thead{
  background: #92929250 !important;
  width: 100% !important;
  color: black;
  font-size: 0.75rem;
  letter-spacing: 2%;
  border-radius: 10px;
  z-index: 9999 !important;
}


</style>