<template>
  <div >
    <div class="source block text-center">
      Total Signups
      <h3>{{totalSignups}}</h3>
    </div>
      <div v-if="countryData">
      <ul class="data-label">
       <li v-for="(value, key) in countryData" :key="key">  {{ key }}: {{ value }}</li>
      </ul>
    </div>
    <div class="heat-map">
      <div v-if="isLoading" class="loading-spinner"></div>
      <v-zoomer >
        <vue-world-map v-if="!isLoading"
        v-bind:countryData="countryData"
        v-bind:defaultCountryFillColor="'#d8d8d8'"
        v-bind:countryStrokeColor="'gray'"
        v-bind:lowColor="'yellow'"
        v-bind:highColor="'red'"
        v-bind:showLegend="true"
        style="object-fit: contain; width: 100%; height: 100%;"
        @countryClicked="handleCountryClick"
      ></vue-world-map>
      <div v-for="(label, country) in dataLabels" :key="country" class="country-label" :style="{ top: label.y, left: label.x, right: label.a, bottom: label.b }">
      {{ country }}: {{ country === 'PH' ? label.value : '' }}
    </div>
      <!-- <MapChart
          :countryData="countryData"
          highColor="#ff0000"
          lowColor="#aaaaaa"
          countryStrokeColor="#909090"
          defaultCountryFillColor="#dadada"
          LangUser="fr"
          />
      -->
      </v-zoomer>
    </div>
    <div class="source block text-center">
   <p>Legend:</p>
      <p>Highest Number: red <span><button style="background-color:red !important; width: 10px; height: 10px;"></button></span></p>
      <p>Lowest Number: yellow <span><button style="background-color:yellow !important; width: 10px; height: 10px;"></button></span></p>
    </div>
    
  </div>
</template>
<script>
import VueWorldMap from "vue-world-map";
//import MapChart from 'vue-map-chart'

export default {
  name: "MyMaChart",
  data() {
    return {
      countryData: {},
      info: null,
      customersGaming:[],
      customersFintech:[],
      isLoading: false,
      ipAddressGaming: [],
      dataValueG: [],
      ipAddressFintech: [],
      dataValueF: [],
      countryMap:[],
      dataValue:[],
      dataLabels: {
        "ASIA": { y: '35%', a: '20%'},
        "NORTH AMERICA": { x: '10%', y: '20%' },
        "SOUTH AMERICA": {  x: '20%', b:'20%',},
        "EUROPE": {  y: '40%', a: '45%'},
        "AUSTRALIA": { a: '10%', b: '10%'},
        "AFRICA":  { x: '50%', y: '60%'}
        // Add more countries and values as needed
      }

    };
  },
  computed:{
    totalSignups(){
            return this.customersFintech.length + this.customersGaming.length;
   }
  },
  mounted() {
    this.mapCountries();
    this.searchByFintech();
    this.searchByGaming();
   
  },
  methods:{
    mapCountries(){
            this.isLoading = true
                const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customer/address/list',
                    };

              this.axios(options)
              .then((response) => { 
                
                this.isLoading = false
                  let statusArr = [];

                  response.data.forEach((el, i) => {
             
                      statusArr.push(el.country);
                  })
            
                  let countMap = statusArr.reduce((country, i) => {
                    country[i] = (country[i] || 0) + 1;

                    return country;
                  
                  }, {});

              

                  let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                    for(let i = 0;i < countArray.length ; i++) {

                        this.countryMap.push(countArray[i].value);
                        this.dataValue.push(countArray[i].count);
                    
                    }
                    this.countryData = countMap
                    console.log(this.countryData)
     
                });
       },
       handleCountryClick(country){
        console.log('test')
        this.isLoading = true
                const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customer/address/list',
                    };

              this.axios(options)
              .then((response) => { 
                
                this.isLoading = false
                  let statusArr = [];

                  response.data.forEach((el, i) => {
             
                      statusArr.push(el.country);
                  })
            
                  this.countryData = statusArr.reduce((country, i) => {
                    country[i] = (country[i] || 0) + 1;

                    return country;
                  }, {});
                  console.log(this.countryData)
  
                  
                  let countMap = statusArr.reduce((el, i) => {
                      el[i] = (el[i] || 0) + 1;
                      return el;
                  }, {});
                  

                  let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                  for(let i = 0;i < countArray.length ; i++) {

                      this.countryMap.push(countArray[i].value);
                      this.dataValue.push(countArray[i].count);
                   
                  }

                  console.log(this.dataValue)

     
                });

       },
      searchByGaming(){
            this.isLoading = true
             const params = {
		          signupHost: 'gaming.ceza.org',
                  itemsPerPage: '100',
                  limit: '50000',
                  offset: '0',
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customerSearchBySignupHost/',
                    params
                    };

              this.axios(options)
              .then((response) => { 
                this.isLoading = false
                this.customersGaming = response.data

                  let statusArr = [];

                  response.data.forEach((el, i) => {
                      statusArr.push(el.activationIpAddress);
                  })
                  

                  let countMap = statusArr.reduce((el, i) => {
                      el[i] = (el[i] || 0) + 1;
                      return el;
                  }, {});

                  let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                  for(let i = 0;i < countArray.length ; i++) {

                      this.ipAddressGaming.push(countArray[i].value);
                      //this.dataValueG.push(countArray[i].count);
                   
                  }

     
                });
       },
        searchByFintech(){
            this.isLoading = true
             const params = {
		          signupHost: 'fintech.ceza.org',
                  itemsPerPage: '100',
                  limit: '50000',
                  offset: '0',
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customerSearchBySignupHost/',
                    params
                    };

              this.axios(options)
              .then((response) => { 
                this.isLoading = false
                this.customersFintech = response.data

                  let statusArr = [];

                  response.data.forEach((el, i) => {
                      statusArr.push(el.activationIpAddress);
                  })
                  

                  let countMap = statusArr.reduce((el, i) => {
                      el[i] = (el[i] || 0) + 1;
                      return el;
                  }, {});

                  let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                  for(let i = 0;i < countArray.length ; i++) {

                      this.ipAddressFintech.push(countArray[i].value);
                      //this.dataValueF.push(countArray[i].count);
                   
                  }

     
                });
    },
  },
  components: {
    VueWorldMap,
    
  },
  filters: {
    date(input) {
      return new Date(input).toLocaleString();
    },
    number(input) {
      let num = Number(input);

      return num.toLocaleString();
    }
  }
};
</script>

<style scoped>
body {
  background: #fafafa;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-left: 10px;
  margin-right: 10px;
}
.data-label {
  position: absolute;
  width: 100px;
    height: 200px;
    margin: 20px auto;
    background: white;
    border-color:white;
    border-width:2px;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
    font-size: 10px;
}
.country-label {
  position: absolute;
  font-size: 12px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
}
a,
a:active,
a:visited {
  color: #42b983;
}
h2 {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.source a {
  text-transform: uppercase;
  font-weight: bold;
}
.heat-map {
  width: 100%;
  max-width: 400px;
  margin: 20px auto 30px auto;
}
.block {
  margin: 40px 0 10px 0;
}
.display-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.update-info {
  padding: 10px;
}
</style>
